/* global send_email, qvapp */

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ReservationCustomerLocations from "./ReservationCustomerLocations";
import Separator from "../common/components/Separator";
import SvgButton from "../common/components/SvgButton";

import lang from "../common/lang/de.json";

import { formatString } from "../common/utils";

import {
    selectIsReservation,
    selectReservationCustomer,
    selectReservationHeader,
    setNewCustomer,
    selectReservationArticles,
    selectReservationCustomerLocations,
    selectReservationCostCenter
} from './store/reservationSlice';

const ReservationCustomer = () => {
    const dispatch = useDispatch();

    const isReservation = useSelector(selectIsReservation);
    const reservationHeader = useSelector(selectReservationHeader);
    const reservationCustomer = useSelector(selectReservationCustomer);
    const reservationArticles = useSelector(selectReservationArticles);
    const reservationCustomerLocations = useSelector(selectReservationCustomerLocations);
    const costCenter = useSelector(selectReservationCostCenter);

    const isAnonymous = reservationHeader && reservationHeader.anonymous == 1
    const collectorIdNumber = reservationHeader && reservationHeader.studierenden_nr
    const customerName = reservationCustomer ? reservationCustomer.firma : "";
    const customerEmail = reservationCustomer ? reservationCustomer.email : "";
    const callbackNumber = reservationCustomer ? reservationCustomer.ruckrufnr : "";
    const costCenterInfo = costCenter ? formatString(lang.reservation.cost_center_info, costCenter.number, costCenter.responsible) : "";

    const onCustomerSelected = (customerId) => {
        dispatch(setNewCustomer(customerId));
    }

    const changeCustomer = () => {
        let articleTenantId = null;
        let articlesLocations = [];

        if (reservationArticles && reservationArticles.length) {
            articleTenantId = reservationArticles[0].tenant_id;

            reservationArticles.map(art => {
                if (art.has_subarticles && art.subarticles.length > 0) {
                    art.subarticles.map(subart => {
                        if (subart.locationId) {
                            articlesLocations.push(subart.locationId);
                        }
                    });
                } else {
                    if (art.locationId) {
                        articlesLocations.push(art.locationId);
                    }
                }
            });

            // Make array unique
            articlesLocations = articlesLocations.filter((v, i, a) => a.indexOf(v) === i);
        }
        window.openCustomerTable(articleTenantId, onCustomerSelected, articlesLocations);
    }

    const textSend = isReservation ? qvapp.i18n.t("label_reservation") : qvapp.i18n.t("label_request");
    const fullTextSend = textSend + " " + lang.send;

    const textChangeCustomer = formatString(lang.reservation.change_customer, qvapp.i18n.t("label_lender"))

    return (
        <div className="customer">
            <span className="name_label">{qvapp.i18n.t("label_lender")}:</span>{customerName}
            {!isAnonymous &&
                <>
                    <Separator />
                    {customerEmail}
                    {reservationCustomerLocations && reservationCustomerLocations.length > 0 &&
                        <>
                            <Separator />
                            <ReservationCustomerLocations />
                        </>
                    }

                    {callbackNumber !== "" &&
                        <>
                            <Separator />
                            {lang.callback_number}: {callbackNumber}
                        </>
                    }

                    {collectorIdNumber !== "" &&
                        <>
                            <Separator />
                            {lang.collector_id_number}: {collectorIdNumber}
                        </>
                    }

                    {costCenterInfo !== "" &&
                        <>
                            <Separator />
                            {costCenterInfo}
                        </>
                    }

                    <Separator />
                    <SvgButton svgRef="icon-knob" textRight={textChangeCustomer} onClick={changeCustomer} />

                    <Separator />
                    <SvgButton svgRef="icon-mail" textRight={fullTextSend} onClick={() => send_email(reservationCustomer.customer_address_id)} />
                </>
            }
        </div>
    );
}

export default ReservationCustomer;