import { client } from '../../common/api/client';

const settingsBaseiUrl = "/includes/einstellung/";
const baseApiUrl = settingsBaseiUrl + "api/index.php?method=";

function getApiUrl(action) {
    return baseApiUrl + action;
}

class SettingsPriceApi {

    static get() {
        return client.post(getApiUrl("getPricingSettings"));
    }

    static set(enablePrices, costCenterInCheckout) {
        return client.post(getApiUrl("updatePricingSettings"), {
            enablePrices,
            costCenterInCheckout
        });
    }

    static getDownloadUrlForTemplate() {
        return settingsBaseiUrl + "downloadCostCentersTemplate.php";
    }

    static uploadCostCentersImportFile(file) {
        return client.fileUpload(getApiUrl("uploadCostCentersImportFile"), "import_file", file);
    }

    static getCostCentersData(searchTerm, rowsOnPage, pageNumber) {
        return client.post(getApiUrl("getCostCentersData"), {
            searchTerm,
            rowsOnPage,
            pageNumber
        });
    }
}

export default SettingsPriceApi;
