import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ActionButton from "../../common/components/ActionButton";

import ArticlesTableHeader from "./ArticlesTableHeader";
import ArticlesTableContent from "./ArticlesTableContent";

import ScanArticlesPopup from "./ScanArticlesPopup";
import EnterDatePopup from "../../common/components/EnterDatePopup";

import lang from "../../common/lang/de.json";

import { isRecurrentReservation } from './ArticlesTableEntry/ArticlesUtils';

import {
    selectIsReservation,
    selectReservationCustomer,
    selectReservationArticles,
    addNewArticle,
    handoutAll,
    returnAll
} from '../store/reservationSlice';

import { convertDateForServer } from '../../common/utils';

import Popup from "../../common/components/Popup";

const ReservationArticles = () => {
    const dispatch = useDispatch();

    const isReservation = useSelector(selectIsReservation);
    const reservationCustomer = useSelector(selectReservationCustomer);
    const articles = useSelector(selectReservationArticles);
    const isRecurrent = isRecurrentReservation(articles);

    const [showScanArticlesPopup, setShowScanArticlesPopup] = useState(false);
    const [showScanIsNotPossible, setShowScanIsNotPossible] = useState(false);

    const [showDatePopupForHandoutAll, setShowDatePopupForHandoutAll] = useState(false);
    const [showDatePopupForReturnAll, setShowDatePopupForReturnAll] = useState(false);
    
    const onArticleSelected = (article_id, from, to, availableSubarticlesList = "") => {
        const fromForServer = convertDateForServer(from);
        const toForServer = convertDateForServer(to);

        const availableSubarticles = String(availableSubarticlesList).split(",");

        dispatch(addNewArticle({article_id, from: fromForServer, to: toForServer, subarticles_already_in_contract: [], available_subarticles: availableSubarticles}));
    }
    
    const onAddArticle = () => {
        let articleTenantId = null;
        let customer = reservationCustomer;
        let selectedLocation = '0';

        if(articles && articles.length) {
            articleTenantId = articles[0].tenant_id;
            selectedLocation = articles[0]?.subarticles ? articles[0].subarticles[0].locationId : articles[0].locationId;
        } else {
            selectedLocation = customer.locations[0] ? customer.locations[0].id : '0';
        }
        
        // console.log("articleTenantId: ", articleTenantId);
        
        window.openArticleTable(customer, articleTenantId, articles.map(a => a.a_id) /* articles already in contract */, onArticleSelected, selectedLocation);
    }

    const onShowScanArticlesPopup = () => {
        if(isRecurrent) {
            setShowScanIsNotPossible(true);
        } else {
            setShowScanArticlesPopup(true);
        }
    };

    const onHandoutAll = (date) => {
        dispatch(handoutAll(date));

        setShowDatePopupForHandoutAll(false);
    }

    const onReturnAll = (date) => {
        dispatch(returnAll(date));

        setShowDatePopupForReturnAll(false);
    }
    
    return (
        <div className="articles_wrapper dash-block-container">
            <div className="article_buttons" style={{justifyContent: 'flex-end'}}>
                <ActionButton onClick={onAddArticle} text={lang.reservation.add_article} style={{marginRight: 'auto'}} />

                {isReservation && !isRecurrent &&
                    <>
                        <ActionButton onClick={() => setShowDatePopupForHandoutAll(true)} text={lang.reservation.handout_all} style={{marginRight: '10px'}} />
                        <ActionButton onClick={() => setShowDatePopupForReturnAll(true)} text={lang.reservation.return_all} style={{marginRight: '10px'}} />
                    </>
                }

                {isReservation &&
                    <ActionButton onClick={onShowScanArticlesPopup} text={lang.reservation.scan_articles} className="" />
                }

                {showScanArticlesPopup &&
                    <ScanArticlesPopup onCancelClicked={() => setShowScanArticlesPopup(false)} />
                }

                {showScanIsNotPossible &&
                    <Popup title={lang.reservation.scan_articles} showButtons={false} onCancelClicked={() => setShowScanIsNotPossible(false)}>
                        <div className="info">
                            {lang.reservation.scan_articles_not_possible_for_reccurent_reservations}                            
                        </div>
                    </Popup>
                }

                {showDatePopupForHandoutAll &&
                    <EnterDatePopup title={lang.reservation.handout_all_title} showButtons={true} onOk={onHandoutAll} onCancel={() => setShowDatePopupForHandoutAll(false)} />
                }

                {showDatePopupForReturnAll &&
                    <EnterDatePopup title={lang.reservation.return_all_title} showButtons={true} onOk={onReturnAll} onCancel={() => setShowDatePopupForReturnAll(false)} />
                }
            </div>

            <div className="articles qv_table">
                <ArticlesTableHeader />

                <ArticlesTableContent />
            </div>
        </div>
    );
}

export default ReservationArticles;