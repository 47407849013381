
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SettingsPriceApi from "../api/SettingsPriceApi";

const initialState = {
    status: 'idle',
    error: null,

    enablePrices: false,
    costCenterInCheckout: false,
    lastCostCenterImport: null,

    rowsOnPage: 20,
    pageNumber: 1,
    totalRows: 0,

    searchTerm: "",

    costCenterData: [],
};

export const getSettingsPrice = createAsyncThunk(
    'settings_price/get',
    async () => {
        try {
            const response = await SettingsPriceApi.get();
            return response;
        } catch (error) {
            throw Error('Failed to fetch settings price');
        }
    }
);

export const setSettingsPrice = createAsyncThunk(
    'settings_price/set',
    async ({enablePrices, costCenterInCheckout}) => {
        try {
            const response = await SettingsPriceApi.set(enablePrices, costCenterInCheckout);
            return response;
        } catch (error) {
            throw Error('Failed to set settings price');
        }
    }
);

export const getCostCentersData = createAsyncThunk(
    'settings_price/get_cost_centers_data',
    async (_noParams, { getState }) => {
        const rowsOnPage = getState().settings_price.rowsOnPage;
        const pageNumber = getState().settings_price.pageNumber;

        // const sortField = getState().settings_price.sortField;
        // const sortDirection = getState().settings_price.sortDirection;
        
        const searchTerm = getState().settings_price.searchTerm;
        
        const response = await SettingsPriceApi.getCostCentersData(searchTerm, rowsOnPage, pageNumber);
        return response;
    }
)

const setStatePricingSettings = (state, response) => {
    state.enablePrices = response.pricing_settings.prices_enabled;
    state.costCenterInCheckout = response.pricing_settings.mandatory_cost_center_in_checkout;
    state.lastCostCenterImport = response.pricing_settings.last_cost_center_import;
}

export const slice = createSlice({
    name: 'settings_price',
    initialState,
    reducers: {
        setEnablePrices: (state, action) => {
            state.enablePrices = action.payload;
        },
        setCostCenterInCheckout: (state, action) => {
            state.costCenterInCheckout = action.payload;
        },
        setRowsOnPage: (state, action) => {
            state.rowsOnPage = action.payload;
        },
        setPageNumber: (state, action) => {
            state.pageNumber = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSettingsPrice.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getSettingsPrice.fulfilled, (state, action) => {
                state.status = 'succeeded';
                
                const response = action.payload;
                setStatePricingSettings(state, response);
            })
            .addCase(getSettingsPrice.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(setSettingsPrice.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(setSettingsPrice.fulfilled, (state, action) => {
                state.status = 'succeeded';
                
                const response = action.payload;
                setStatePricingSettings(state, response);
            })
            .addCase(setSettingsPrice.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getCostCentersData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCostCentersData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.costCenterData = action.payload.cost_center_data;
                state.totalRows = parseInt(action.payload.cost_center_count);
            })
            .addCase(getCostCentersData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const selectIsLoading = state => state.settings_price.status === "loading";
export const selectEnablePrices = state => state.settings_price.enablePrices;
export const selectCostCenterInCheckout = state => state.settings_price.costCenterInCheckout;
export const selectLastCostCenterImport = state => state.settings_price.lastCostCenterImport;
export const selectPageNumber = state => state.settings_price.pageNumber;
export const selectRowsOnPage = state => state.settings_price.rowsOnPage;
export const selectTotalRows = state => state.settings_price.totalRows;
export const selectCostCenterData = state => state.settings_price.costCenterData;
export const selectSearchTerm = state => state.settings_price.searchTerm;

export const { setEnablePrices, setCostCenterInCheckout, setRowsOnPage, setPageNumber, setSearchTerm } = slice.actions;

export default slice.reducer;