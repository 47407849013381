
import React, { useState } from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";

import SvgButton from './SvgButton'

import lang from '../lang/de.json';

import '../scss/SearchField.scss';

const SearchField = (props) => {
    const [searchTerm, setSearchTerm] = useState(props.searchTerm || "");

    const cssClasses = classNames(["search_field", props.className]);

    const onClick = () => {
        props.onDoSearch(searchTerm);
    }

    const onKeyPress = (event) => {
        if (event.charCode === 13) { // 13 represents the "Enter" key
            props.onDoSearch(searchTerm);
        }
    }

    return (
        <div className={cssClasses}>
            <input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} onKeyPress={onKeyPress} placeholder={lang.search} />
            <SvgButton svgRef="icon-search" className={"search_field_icon"} onClick={onClick} />
        </div>
    );
}

SearchField.propTypes = {
    className: PropTypes.string,
    searchTerm: PropTypes.string,
    onDoSearch: PropTypes.func.isRequired
}

export default SearchField;