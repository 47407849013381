
import React from "react";
import PropTypes from 'prop-types';

const CheckBox = (props) => {
    const defaultStyle = {marginLeft: "1.5rem", marginTop: "1rem", marginBottom: "1rem"};

    const disabled = props.disabled ? { disabled: true } : {};

    return (
        <div style={{ ...defaultStyle, ...props.style }}>
            <input type="checkbox" id={props.htmlIdName} name={props.htmlIdName} checked={props.checked} onChange={props.onChange} {...disabled} />
            <label htmlFor={props.htmlIdName}>{props.text}</label>
        </div>
    );
}

CheckBox.propTypes = {
    htmlIdName: PropTypes.string,
    text: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,

    style: PropTypes.object,    
    disabled: PropTypes.bool,
    
}

export default CheckBox;